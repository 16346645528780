import React from "react"
import { Link } from "gatsby"

const LinkToContact = () => {
  return (
    <div className="contact-btn">
      <Link to="/contactUs">Contact</Link>
    </div>
  )
}

export default LinkToContact
