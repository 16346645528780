import React from "react"
import Layout from "../component/Layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Lightbox from "../component/Lightbox"
import ButtonsF from "../component/ButtonsFHO"
// eslint-disable-next-line
import { Link, animateScroll as scroll } from "react-scroll"

import towels from "../images/icons/001-towel.png"
import heating from "../images/icons/003-hot-thermometer.png"
import nature from "../images/icons/wood copy.png"
import hair from "../images/icons/004-hair-dryer.png"
import pets from "../images/icons/012-animal-prints.png"
import parking from "../images/icons/010-parking.png"
import wood from "../images/icons/001-log.png"
import cutlery from "../images/icons/014-cutlery.png"
import shower from "../images/icons/shower.png"
import fridge from "../images/icons/001-fridge.png"
import cook from "../images/icons/003-oven-mitt.png"
import oven from "../images/icons/002-stone-oven.png"
import toilet from "../images/icons/toilet.png"

import SEO from "../component/SEO"

const frankysHideOut = ({ data }) => {
  return (
    <Layout>
      <SEO title="Franky's Hideout" />

      <section className="hero-content frankys">
        <div id="frank-hero-title" className="hero-title">
          <div className="inner-wrap">
            <h1>Franky's Hideout</h1>
            <h2>South Wales - Just outside Chepstow</h2>
          </div>
        </div>
        <ButtonsF scroll="frankys" />
      </section>
      <section className="intro-content welcome-dch">
        <div className="slogan">
          <h2>Welcome to Franky's Hideout</h2>
          <p>
            This beautiful shepherds hut has everything you could wish for! It
            nestles in the elevated corner of a field,sheltered on two sides by
            woodland with windows and door facing amazing views over the Usk
            Valley to the Black Mountains and the Brecon beacon beyond..
          </p>

          <p>
            There is even a window at the foot of the bed ,so you can lie back
            and enjoy the view without leaving your bed! Franky's Hideout is
            included when you book the Country House.
          </p>
        </div>
      </section>
      <section className="three-pictures-section glamping_three_home">
        <Link
          activeclassName="active"
          to="frankys"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="frankys"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-two"
        ></Link>
        <Link
          activeClass="active"
          to="frankys"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-three"
        ></Link>
      </section>
      <section className="intro-content gardens-dch">
        <div className="slogan">
          <h2>A Little Bit More About Her</h2>

          <p>
            She has been handcrafted to a high standard, with built in
            insulation to keep you cool in the summer and snuggly in the winter.
          </p>
          <p>
            They say all good things come in small packages and this is
            definitely true here, She offers everything you could wish for in
            terms of comfort, from an en-suite flushing loo and shower room to
            electric lights and sockets ! this is indeed luxury. There is an oak
            table and two sumptuous velvet arm chairs for cosying up by the log
            burner.
          </p>

          <p>
            Frankys Hideout is the newest member of the family,she shares the 17
            acre site with 2 houses that form Dorlands. She has her own secluded
            site , which is approximately 100 metres away from the nearest
            house.
          </p>
        </div>
      </section>
      <section className="picture-break glamping-break"></section>
      <section className="intro-content cooking-dch">
        <div className="slogan frankys-cook">
          <h2>COOKING AND DINING</h2>
          <p>
            The small but beautifully made kitchen, has cupboard space, hot and
            cold water at the sink, an induction hob and a kettle.
          </p>
          <p>
            For more adventurous cooking, under the gazebo outside, there is a
            uni wood pellet fired pizza oven and a BBQ. The oven really does
            what is says ’on the can’ once warmed up it will cook the pizzas
            perfectly in 2 -3 mins. See the video below! Or you may prefer to
            light the fire pit and simply relax under the night sky.
          </p>
          {/* <iframe
            title="pizza oven video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/YyR5Hbi2iig"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullscreen
          ></iframe> */}
        </div>
      </section>
      <section className="two-pictures-section glamping_two_home">
        <Link
          activeClass="active"
          to="frankys"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="frankys"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture
    pic-two"
        ></Link>
      </section>
      <section className="info-content">
        <div className="amenities-content">
          <h4>Amenities</h4>
          <div className="grid">
            <div className="icons">
              <img src={towels} alt="towel and bedding icon" />
              <p className="iconstext">Towels &amp; Bedding</p>
            </div>
            <div className="icons">
              <img src={heating} alt="icon" />
              <p className="iconstext">Electric Heater</p>
            </div>
            <div className="icons">
              <img src={nature} alt="icon" />
              <p className="iconstext">Enjoy Nature</p>
            </div>
            <div className="icons">
              <img src={hair} alt="icon" />
              <p className="iconstext">Hair Dryers</p>
            </div>
            <div className="icons">
              <img src={pets} alt="icon" />
              <p className="iconstext">Pet Friendly</p>
            </div>
            <div className="icons">
              <img src={parking} alt="icon" />
              <p className="iconstext">On site Parking</p>
            </div>
            <div className="icons">
              <img src={wood} alt="icon" />
              <p className="iconstext">Wood Burner</p>
            </div>
            <div className="icons">
              <img src={cutlery} alt="icon" />
              <p className="iconstext">Cutlery</p>
            </div>
            <div className="icons">
              <img src={shower} alt="icon" />
              <p className="iconstext">Shower</p>
            </div>
            <div className="icons">
              <img src={fridge} alt="icon" />
              <p className="iconstext">Fridge</p>
            </div>
            <div className="icons">
              <img src={cook} alt="icon" />
              <p className="iconstext">Cooking Facilities</p>
            </div>
            <div className="icons">
              <img src={oven} alt="icon" />
              <p className="iconstext">Pizza Oven</p>
            </div>
            <div className="icons">
              <img src={toilet} alt="icon" />
              <p className="iconstext">En suite WC</p>
            </div>
          </div>
        </div>
      </section>

      <section className="galleries" id="frankys">
        <h2>Franky's Hide Out Gallery</h2>
        <Lightbox images={data.frankys.edges} />
      </section>
    </Layout>
  )
}

frankysHideOut.propTypes = {
  data: PropTypes.object.isRequired,
}

export default frankysHideOut

export const galleryQueryTwo = graphql`
  query FrankysGallery {
    frankys: allFile(filter: { sourceInstanceName: { eq: "frankys" } }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
