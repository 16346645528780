import React from "react"
import LinkTo from "./LinkToContact"
// eslint-disable-next-line
import { Link, animateScroll as scroll } from "react-scroll"
import "./scss/buttonsFHO.scss"
const Buttons = ({ scroll, brochure }) => {
  return (
    <div id="hero-buttons-fho">
      <div className="inner-wrap">
        <div className="gallery-btn">
          <Link
            activeClass="active"
            to={scroll}
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >
            Gallery
          </Link>
        </div>
        {/* <div className="availability-btn">
          <Link
            activeClass="active"
            to={brochure}
            spy={true}
            smooth={true}
            offset={-140}
            duration={500}
          >
            Brochures
          </Link>
        </div> */}
        <LinkTo />
      </div>
    </div>
  )
}

export default Buttons
